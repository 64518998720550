var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a-row',{attrs:{"gutter":[16, 16]}},[(_vm.isSubjectTest)?_c('a-col',{attrs:{"span":"4"}},[_c('a-form-item',{attrs:{"label":"Отображение результатов"}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"mode":"multiple","value":_vm.data.viewType,"placeholder":"Отображение результатов"},on:{"change":function($event){return _vm.updateField({
                            path: 'resultPage.viewType',
                            value: $event,
                        })}}},[_c('a-select-option',{attrs:{"value":"primary"}},[_vm._v(" в первичных баллах ")]),_c('a-select-option',{attrs:{"value":"percent"}},[_vm._v("в процентах")]),_c('a-select-option',{attrs:{"value":"test"}},[_vm._v(" в тестовых баллах ")])],1)],1)],1):_vm._e(),_c('a-col',{attrs:{"span":"8"}},[_c('a-form-item',{attrs:{"label":"Текст кнопки"}},[_c('a-input',{attrs:{"placeholder":"Текст кнопки","value":_vm.data.btnText},on:{"change":function($event){return _vm.updateField({
                            path: 'resultPage.btnText',
                            value: $event.target.value,
                        })}}})],1)],1),_c('a-col',{attrs:{"span":"8"}},[_c('a-form-item',{attrs:{"label":"URL кнопки"}},[_c('a-input',{attrs:{"placeholder":"URL кнопки","value":_vm.data.btnUrl},on:{"change":function($event){return _vm.updateField({
                            path: 'resultPage.btnUrl',
                            value: $event.target.value,
                        })}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }