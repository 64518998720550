
import { Vue, Component } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { namespace } from 'vuex-class';
import { IQuestion } from '@umax/proftest-types';

const test = namespace('Test');

@Component({
    components: { Draggable },
})
export default class DraggableQuestionsBody extends Vue {
    @test.State((state) => state.currentTest.questions)
    private data!: IQuestion[];
}
