
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ICategoryGroup, ITest } from '@umax/proftest-types';
import { generateUUID } from '@/utils';
import { UploadFile } from 'ant-design-vue/types/upload';

const test = namespace('Test');
const { VUE_APP_BASE_URL = '' } = process.env;

@Component
export default class CategoryGroupsTab extends Vue {
    @test.State((state) => state.currentTest)
    private currentTest!: ITest;

    @test.Action
    updateField!: (data: { path: string; value: unknown }) => void;

    private selectedGroupId: null | string = null;
    private fileList: Array<Record<string, string>> = [];


    addCategoryGroup(): void {
        const groups = this.currentTest.categoryGroups || [];
        this.updateField({
            path: 'categoryGroups',
            value: [
                ...groups,
                {
                    _id: generateUUID(),
                    title: 'Группа',
                    categories: [],
                },
            ],
        });
    }

    removeCategoryGroup(id: string): void {
        this.updateField({
            path: 'categoryGroups',
            value: this.currentTest.categoryGroups.filter(
                (group: ICategoryGroup) => group._id !== id,
            ),
        });
    }

    addCategoryToGroup(): void {
        this.updateField({
            path: `categoryGroups`,
            value: this.currentTest.categoryGroups.map((group: ICategoryGroup) => ({
                ...group,
                categories:
                    group._id === this.selectedGroupId
                        ? [...group.categories, { _id: generateUUID(), name: '' }]
                        : group.categories,
            })),
        });
    }

    removeCategoryFromGroup(id: string): void {
        this.updateField({
            path: `categoryGroups`,
            value: this.currentTest.categoryGroups.map((group: ICategoryGroup) => ({
                ...group,
                categories:
                    group._id === this.selectedGroupId
                        ? group.categories.filter((cat) => cat._id !== id)
                        : group.categories,
            })),
        });
    }

    selectGroup(groupId: string): void {
        this.selectedGroupId = groupId;

        const idx = this.currentTest.categoryGroups.findIndex(
            (group: ICategoryGroup) => group._id === this.selectedGroupId,
        );
        const currentImage = this.currentTest.categoryGroups[idx].imageUrl;

        this.fileList = currentImage ? [{ name: this.currentTest.categoryGroups[idx].imageUrl, uid: '1' }] : [];
    }

    get selectedGroup(): ICategoryGroup | null {
        if (this.selectedGroupId) {
            const group = this.currentTest.categoryGroups.find(
                (group: ICategoryGroup) => group._id === this.selectedGroupId,
            );
            return group || null;
        }
        return null;
    }

    changeGroupTitle(event: Event & { target: { value: string } }): void {
        const idx = this.currentTest.categoryGroups.findIndex(
            (group: ICategoryGroup) => group._id === this.selectedGroupId,
        );
        this.updateField({
            path: `categoryGroups[${idx}].title`,
            value: event.target.value,
        });
    }

    changeCategory(id: string, text: string): void {
        this.updateField({
            path: `categoryGroups`,
            value: this.currentTest.categoryGroups.map((group: ICategoryGroup) => ({
                ...group,
                categories:
                    group._id === this.selectedGroupId
                        ? group.categories.map((cat) =>
                              cat._id === id ? { ...cat, name: text } : cat,
                          )
                        : group.categories,
            })),
        });
    }

}
