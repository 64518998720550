<template>
    <div>
        <div 
            v-for="(legendItem, index) in iconLegend"
            :key="legendItem.id"
            :style="{ display: 'flex', alignItems: 'flex-start' }"
        >
            <a-form-item
                label="Иконка"
                :style="{ marginRight: '16px',  }"
            >
                <a-select
                    :dropdown-match-select-width="false"
                    v-model="legendItem.icon"
                    style="width: 100%"
                    placeholder="Выберите иконку"
                    option-label-prop="title"
                    size="large"
                    :options="getOptions(index)"
                />
            </a-form-item>

            <a-form-item
                :style="{ marginRight: '16px', width: '100%' }"
                label="Заголовок"
            >
                <a-input v-model="legendItem.title" />
            </a-form-item>

            <a-form-item
                :style="{ marginRight: '16px', width: '100%' }"
                label="Ссылка"
            >
                <a-input v-model="legendItem.href" />
            </a-form-item>

            <a-popconfirm
                title="Вы действительно хотите удалить категорию?"
                ok-text="Да"
                cancel-text="Нет"
                @confirm="removeLegendItem(index)"
            >
                    <a-button
                        :style="{ marginTop: '44px' }"
                        type="danger"
                        v-text="'Удалить'"
                    />
            </a-popconfirm>
        </div>
        <div :style="{ display: 'flex', justifyContent: 'flex-end' }">
            <a-button :disabled="isAddDisabled" @click="addLegendItem">
                Добавить
            </a-button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            iconLegend: [],
            iconOptions: [
                { value: 'mathematics', icon: 'mathematics' },
                { value: 'biology', icon: 'biology' },
                { value: 'chemistry', icon: 'chemistry' },
                { value: 'education', icon: 'education' },
                { value: 'geography', icon: 'geography' },
                { value: 'higher-education', icon: 'higher-education' },
                { value: 'history', icon: 'history' },
                { value: 'informatika', icon: 'informatika' },
                { value: 'literature', icon: 'literature' },
                { value: 'obshestvoznanie', icon: 'obshestvoznanie' },
                { value: 'physics', icon: 'physics' },
                { value: 'russian', icon: 'russian' },
                { value: 'english', icon: 'english' },
                { value: 'college', icon: 'college' },
                { value: 'university', icon: 'university' },
            ]
        }
    },
    mounted () {
        this.iconLegend = [...this.$store.state.Test.currentTest.iconLegend ];
    },
    methods: {
        ...mapActions({
            updateField: 'Test/updateField',
        }),
        addLegendItem() {
            this.iconLegend.push({});
        },
        removeLegendItem(index) {
            this.iconLegend.splice(index, 1);
        },
        getOptions(index) {
            const currentIcon = this.iconLegend[index].icon;
            const filterIcons = this.iconLegend.flatMap(({ icon }) => icon === currentIcon ? [] : icon)

            return this.iconOptions
                .map(option =>
                    Object.assign({}, option, {
                        title: this.$createElement('img', { attrs: { src: require(`@/assets/${option.icon}.png`) }}),
                        label: this.$createElement('img', { attrs: { src: require(`@/assets/${option.icon}.png`) }}),
                    })
                )
                .filter(({ icon }) => !filterIcons.includes(icon));
        },
    },
    computed: {
        isAddDisabled() {
            const lastItem = this.iconLegend[this.iconLegend.length - 1];

            if (!this.iconLegend.length) return false;
            if (!lastItem?.icon && !lastItem?.title && !lastItem?.href) return true;
            else return false;
        },
    },
    watch: {
        iconLegend: {
            deep: true,
            handler(newValue) {
                this.updateField({
                    path: 'iconLegend',
                    value: newValue,
                })
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.ant-select-selection-selected-value {
    img {
        height: 34px;
    }
}
</style>