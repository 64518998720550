import Vue from 'vue';
import Vuex from 'vuex';
import { Auth } from '@/store/modules/auth';
import { Project } from '@/store/modules/project';
import { Test } from '@/store/modules/test';
import { User } from '@/store/modules/users';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        Auth,
        Project,
        Test,
        User,
    },
});
