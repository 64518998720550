
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ITest } from '@umax/proftest-types';
import { IModuleStatus } from '@/@types';
import format from 'date-fns/format';

const test = namespace('Test');

@Component
export default class Tests extends Vue {
    @test.State((state) => state.tests)
    private tests!: ITest[];

    @test.State((state) => state.error)
    private error!: string | null;

    @test.State((state) => state.status)
    private status!: IModuleStatus;

    @test.Action
    private fetchTests!: () => void;

    @test.Action
    private removeTest!: (id: string) => void;

    @test.Action
    private cloneTest!: (params: { id: string; data: Record<string, string> }) => void;

    @test.Action
    private createTest!: () => Promise<ITest | null>;

    private columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Адрес',
            dataIndex: 'alias',
            key: 'alias',
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '12%',
            scopedSlots: { customRender: 'createdAt' },
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '12%',
            scopedSlots: { customRender: 'updatedAt' },
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            scopedSlots: { customRender: 'action' },
        },
    ];

    @Watch('status')
    testsLoaded(status: IModuleStatus): void {
        if (status === 'error' && this.error) {
            this.$message.error(this.error);
        }
    }

    mounted(): void {
        this.fetchTests();
    }

    formatDate(date: string): string {
        return format(new Date(date), 'dd.MM.yy HH:mm');
    }

    async createNewTest(): Promise<void> {
        const test = await this.createTest();
        if (test) {
            this.$router.push(`/tests/${test._id}`);
        }
    }

    async clone(id: string, name: string, alias: string): Promise<void> {
        await this.cloneTest({
            id,
            data: { name, alias },
        });
    }
}
