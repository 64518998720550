
import { namespace, State } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ISignInRequest, ISignInResponse } from '@umax/proftest-types';

import ru from 'ant-design-vue/lib/locale-provider/ru_RU';
import AuthModal from '@/components/modals/AuthModal.vue';
import { isSuperPuperAdmin } from '@/utils';

const auth = namespace('Auth');

@Component({
    // eslint-disable-next-line no-undef
    components: {
        AuthModal,
    },
})
export default class App extends Vue {
    private locale = ru;
    private authModalVisible = false;
    private isAdmin = isSuperPuperAdmin();

    @State((state) => state.Auth.user) user!: ISignInResponse;
    @State((state) => state.Auth.role) role!: string;

    mounted(): void {
        this.checkAuth();
    }

    @auth.Action
    public checkAuth!: () => void;

    @auth.Action
    public signIn!: (data: ISignInRequest) => void;

    @auth.Action
    public logOut!: () => void;

    @Watch('user')
    userChanged(val: ISignInResponse): void {
        if (val) {
            this.authModalVisible = false;
        }
        this.isAdmin = isSuperPuperAdmin();

        if (this.role === 'readonly') this.$router.push('/results')
    }
}
