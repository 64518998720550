var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"span":"6"}},[_c('a-form-item',{attrs:{"label":"Тип теста"}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"value":_vm.currentTest.type},on:{"change":function($event){return _vm.updateField({
                            path: 'type',
                            value: $event,
                        })}}},[_c('a-select-option',{attrs:{"value":"weight"}},[_vm._v(" Вес ")]),_c('a-select-option',{attrs:{"value":"subject"}},[_vm._v(" Предметный ")]),_c('a-select-option',{attrs:{"value":"prof"}},[_vm._v(" Проф ")]),_c('a-select-option',{attrs:{"value":"prof2"}},[_vm._v(" Профтест 2 ")])],1)],1)],1),_c('a-col',{attrs:{"span":"6"}},[_c('a-form-item',{attrs:{"label":"Название теста"}},[_c('a-input',{attrs:{"value":_vm.currentTest.name},on:{"change":function($event){return _vm.updateField({
                            path: 'name',
                            value: $event.target.value,
                        })}}})],1)],1),_c('a-col',{attrs:{"span":"4"}},[_c('a-form-item',{attrs:{"label":"URL теста"}},[_c('a-input',{attrs:{"value":_vm.currentTest.alias},on:{"change":function($event){return _vm.updateField({
                            path: 'alias',
                            value: $event.target.value,
                        })}}})],1)],1),_c('a-col',{attrs:{"span":"6"}},[_c('a-form-item',{attrs:{"label":"Показывать пользователю результат"}},[_c('a-checkbox',{attrs:{"checked":_vm.currentTest.showResultInEditScreen},on:{"change":function($event){return _vm.updateField({
                            path: 'showResultInEditScreen',
                            value: $event.target.checked,
                        })}}},[_vm._v(" Показывать ")])],1)],1)],1),_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"span":"3"}},[_c('a-form-item',{attrs:{"label":"CRM ID"}},[_c('a-input',{attrs:{"value":_vm.currentTest.crmId},on:{"change":function($event){return _vm.updateField({
                            path: 'crmId',
                            value: $event.target.value,
                        })}}})],1)],1),_c('a-col',{attrs:{"span":"3"}},[_c('a-form-item',{attrs:{"label":"Класс"}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"mode":"multiple","value":_vm.currentTest.grade,"placeholder":"Выберите классы"},on:{"change":function($event){return _vm.updateField({
                            path: 'grade',
                            value: $event,
                        })}}},_vm._l(([5, 6, 7, 8, 9, 10, 11]),function(grade){return _c('a-select-option',{key:grade,attrs:{"value":grade}},[_vm._v(" "+_vm._s(grade)+" ")])}),1)],1)],1),(_vm.currentTest.type === 'subject')?_c('a-col',{attrs:{"span":"4"}},[_c('a-form-item',{attrs:{"label":"Лимит по времени"}},[_c('a-time-picker',{attrs:{"value":_vm.testLimitTime},on:{"change":function($event){_vm.updateField({
                            path: 'timeLimit',
                            value: $event ? $event.toDate() : null,
                        })}}},[_c('a-button',{attrs:{"slot":"addon","size":"small","type":"primary"},on:{"click":function($event){return _vm.updateField({
                                path: 'timeLimit',
                                value: null,
                            })}},slot:"addon"},[_vm._v(" Очистить ")])],1)],1)],1):_vm._e(),_c('a-col',{attrs:{"span":"4"}},[_c('a-form-item',{attrs:{"label":"Тема отбивки"}},[_c('a-input',{attrs:{"value":_vm.currentTest.mailSettings.subject},on:{"change":function($event){return _vm.updateField({
                            path: 'mailSettings.subject',
                            value: $event.target.value,
                        })}}})],1)],1),_c('a-col',{attrs:{"span":"4"}},[_c('a-form-item',{attrs:{"prop":"icon","label":"Шаблон отбивки"}},[_c('a-upload',_vm._b({attrs:{"name":"file","file-list":_vm.mailTemplate},on:{"change":(info) => _vm.change('mailTemplate', info)}},'a-upload',_vm.uploadProps('text/html', 'proftest/mailTemplates'),false),[(!_vm.mailTemplate)?_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Загрузить ")],1):_vm._e()],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"span":"6"}},[_c('a-form-item',{attrs:{"label":"Заголовок"}},[_c('a-input',{attrs:{"value":_vm.currentTest.title},on:{"change":function($event){return _vm.updateField({
                            path: 'title',
                            value: $event.target.value,
                        })}}})],1)],1),_c('a-col',{attrs:{"span":"6"}},[_c('a-form-item',{attrs:{"label":"Описание"}},[_c('a-textarea',{attrs:{"allow-clear":"","value":_vm.currentTest.description},on:{"change":function($event){return _vm.updateField({
                            path: 'description',
                            value: $event.target.value,
                        })}}})],1)],1),_c('a-col',{attrs:{"span":"6"}},[_c('a-form-item',{attrs:{"label":"Текст на кнопке"}},[_c('a-input',{attrs:{"value":_vm.currentTest.btnText},on:{"change":function($event){return _vm.updateField({
                            path: 'btnText',
                            value: $event.target.value,
                        })}}})],1)],1),_c('a-col',{attrs:{"span":"6"}},[_c('a-form-item',{attrs:{"prop":"icon","label":"Иконка"}},[(_vm.currentTest.icon)?_c('img',{staticStyle:{"max-width":"60px"},attrs:{"src":_vm.imageUrl}}):_vm._e(),_c('a-upload',_vm._b({attrs:{"name":"file","file-list":_vm.icon},on:{"change":(info) => _vm.change('icon', info)}},'a-upload',_vm.uploadProps(),false),[(!_vm.imageUrl)?_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v("Загрузить")],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }