var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"span":"8"}},[_c('a-form-item',{attrs:{"label":"Заголовок"}},[_c('a-input',{attrs:{"placeholder":"Заголовок","value":_vm.data.title},on:{"change":function($event){return _vm.updateField({
                            path: 'finishPage.title',
                            value: $event.target.value,
                        })}}})],1)],1),_c('a-col',{attrs:{"span":"8"}},[_c('a-form-item',{attrs:{"label":"Описание"}},[_c('a-textarea',{attrs:{"placeholder":"Описание","value":_vm.data.description},on:{"change":function($event){return _vm.updateField({
                            path: 'finishPage.description',
                            value: $event.target.value,
                        })}}})],1)],1)],1),_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"span":"8"}},[_c('a-form-item',{attrs:{"label":"URL видео"}},[_c('a-input',{attrs:{"placeholder":"URL видео","value":_vm.data.videoUrl},on:{"change":function($event){return _vm.updateField({
                            path: 'finishPage.videoUrl',
                            value: $event.target.value,
                        })}}})],1)],1),_c('a-col',{attrs:{"span":"8"}},[_c('a-form-item',{attrs:{"label":"Текст кнопки"}},[_c('a-input',{attrs:{"placeholder":"Текст кнопки","value":_vm.data.btnText},on:{"change":function($event){return _vm.updateField({
                            path: 'finishPage.btnText',
                            value: $event.target.value,
                        })}}})],1)],1),_c('a-col',{attrs:{"span":"8"}},[_c('a-form-item',{attrs:{"label":"URL кнопки"}},[_c('a-input',{attrs:{"placeholder":"URL кнопки","value":_vm.data.btnUrl},on:{"change":function($event){return _vm.updateField({
                            path: 'finishPage.btnUrl',
                            value: $event.target.value,
                        })}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }