
import { Vue, Component } from 'vue-property-decorator';
import { IFinishPage } from '@umax/proftest-types';
import { namespace } from 'vuex-class';

const test = namespace('Test');

@Component
export default class TestPassed extends Vue {
    @test.State((state) => state.currentTest.finishPage)
    private data!: IFinishPage;

    @test.Action
    updateField!: (data: { path: string; value: unknown }) => void;
}
