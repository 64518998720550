
import { Vue, Component } from 'vue-property-decorator';
import { IResultPage, TestType } from '@umax/proftest-types';
import { namespace } from 'vuex-class';
import { Test } from '@/store/modules/test';

const test = namespace('Test');

@Component
export default class ResultPageTab extends Vue {
    @test.State((state: Test) => state.currentTest.resultPage)
    private data!: IResultPage;

    @test.State((state: Test) => state.currentTest.type)
    private type!: TestType;

    @test.Action
    updateField!: (data: { path: string; value: unknown }) => void;

    get isSubjectTest(): boolean {
        return this.type === 'subject';
    }
}
