import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import axios from 'axios';
import { omit } from 'lodash';
import { ICreatProject, IProject } from '@umax/proftest-types';
import { IModuleStatus } from '@/@types';

@Module({ namespaced: true })
export class Project extends VuexModule {
    public projects: IProject[] = [];
    public error: string | null = null;
    public status: IModuleStatus = 'idle';

    @Mutation
    public setStatus(status: IModuleStatus): void {
        this.status = status;
    }

    @Mutation
    public setError(error: string | null): void {
        this.error = error;
    }

    @Mutation
    public setProjects(projects: IProject[]): void {
        this.projects = projects;
    }

    @Action
    public async fetchProjects(): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            const { data: projects } = await axios.get('/admin/project');
            this.context.commit('setProjects', projects);
            this.context.commit('setStatus', 'success');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async addProject(data: ICreatProject): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.post('/admin/project', data);
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchProjects');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            const message = e.message ?? e;
            if (message.includes('duplicate')) {
                this.context.commit(
                    'setError',
                    'Ошибка сохранения: URL должен быть уникальным',
                );
            } else {
                this.context.commit('setError', e.message ?? e);
            }
        }
    }

    @Action
    public async updateProject(data: ICreatProject & { id: string }): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.patch('/admin/project', data, {
                params: {
                    id: data.id,
                },
            });
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchProjects');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            const message = e.message ?? e;
            if (message.includes('duplicate')) {
                this.context.commit(
                    'setError',
                    'Ошибка сохранения: URL должен быть уникальным',
                );
            } else {
                this.context.commit('setError', e.message ?? e);
            }
        }
    }

    @Action
    public async addGroup(data: {
        projectId: string;
        name: string;
        description: string;
        color: string;
        icon: string;
    }): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.post('/admin/project/group', omit(data, ['projectId']), {
                params: {
                    id: data.projectId,
                },
            });
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchProjects');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async updateGroup(data: {
        projectId: string;
        name: string;
        description: string;
        color: string;
        icon: string;
        id: string;
    }): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.patch('/admin/project/group', omit(data, ['projectId', 'id']), {
                params: {
                    id: data.projectId,
                    groupId: data.id,
                },
            });
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchProjects');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async removeGroup({
        projectId,
        groupId,
    }: {
        projectId: string;
        groupId: string;
    }): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.delete(`/admin/project/group/${groupId}`, {
                params: {
                    projectId,
                },
            });
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchProjects');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async addTestToGroup({
        projectId,
        groupId,
        testId,
    }: {
        projectId: string;
        groupId: string;
        testId: string;
    }): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.put('/admin/project/group/test', {
                id: projectId,
                groupId,
                testId,
            });
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchProjects');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async removeTestFromGroup({
        projectId,
        groupId,
        testId,
    }: {
        projectId: string;
        groupId: string;
        testId: string;
    }): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.delete(`/admin/project/group/test/${testId}`, {
                params: {
                    id: projectId,
                    groupId,
                },
            });
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchProjects');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async removeProject(projectId: string): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.delete(`/admin/project/${projectId}`);
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchProjects');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async changeDefaultProject(projectId: string): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.patch(
                `/admin/project/default`,
                {},
                {
                    params: {
                        projectId,
                    },
                },
            );
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchProjects');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }
}
