import './class-component-hooks';
import Vue from 'vue';
import axios, { AxiosError, AxiosResponse } from 'axios';
import VueQuillEditor from 'vue-quill-editor';
import 'katex';
import App from './App.vue';
import router from './router';
import { store } from './store';
import './plugins/ant-design-vue.js';
import './plugins/v-mask.js';

const { VUE_APP_BASE_URL = '' } = process.env;

axios.defaults.baseURL = `${VUE_APP_BASE_URL}/api/v1`;
axios.defaults.withCredentials = true;

const isAxiosError = (error: Error): error is AxiosError => {
    return (error as AxiosError).isAxiosError !== undefined;
};

axios.interceptors.response.use(
    (response): AxiosResponse => {
        return response;
    },
    (error: Error): Promise<never> => {
        if (isAxiosError(error)) {
            return Promise.reject(error.response?.data ?? error);
        } else {
            return Promise.reject(error);
        }
    },
);

Vue.config.productionTip = false;

import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'katex/dist/katex.min.css';

Vue.use(VueQuillEditor /* { default global options } */);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
