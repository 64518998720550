
import { Vue, Component } from 'vue-property-decorator';
import { ITest } from '@umax/proftest-types';
import { namespace } from 'vuex-class';
import { generateUUID } from '@/utils';

const test = namespace('Test');

@Component
export default class CategoriesTab extends Vue {
    @test.State((state) => state.currentTest)
    private currentTest!: ITest;

    @test.Action
    updateField!: (data: { path: string; value: unknown }) => void;

    addCategory(): void {
        const categories = this.currentTest?.categories || [];
        this.updateField({
            path: 'categories',
            value: [
                ...categories,
                {
                    _id: generateUUID(),
                    title: 'Категория',
                },
            ],
        });
    }

    removeCategory(idx: number): void {
        const categories = this.currentTest?.categories || [];
        this.updateField({
            path: `categories`,
            value: categories.filter((cat, index) => index !== idx),
        });
    }
}
