import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Projects from '@/views/Projects.vue';
import Tests from '@/views/Tests.vue';
import EditTest from '@/views/EditTest.vue';
import Users from '@/views/Users.vue';
import Results from '@/views/Results.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Projects,
    },
    {
        path: '/tests',
        name: 'Tests',
        component: Tests,
    },
    {
        path: '/tests/:id',
        name: 'TestEdit',
        component: EditTest,
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
    },
    {
        path: '/results',
        name: 'Results',
        component: Results,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
