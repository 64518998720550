
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ITestGroup } from '@umax/proftest-types';
import { Sketch } from 'vue-color';
import { UploadFile } from 'ant-design-vue/types/upload';

interface ColorPickerValue {
    hsl: { h: number; s: number; l: number; a: number };
    hex: string;
    hex8: string;
    rgba: { r: number; g: number; b: number; a: number };
    hsv: { h: number; s: number; v: 0.7066; a: number };
    oldHue: number;
    source: string;
    a: number;
}

const { VUE_APP_S3_HOST = '', VUE_APP_S3_BUCKET } = process.env;

const isColorPickerValue = (
    value: string | ColorPickerValue,
): value is ColorPickerValue => {
    return (value as ColorPickerValue).hex !== undefined;
};

const getColor = (color?: string | ColorPickerValue): string => {
    if (!color) {
        return '';
    }
    if (isColorPickerValue(color)) {
        return color.hex;
    }
    return color;
};

const { VUE_APP_BASE_URL = '' } = process.env;

@Component({
    components: {
        'color-picker': Sketch,
    },
})
export default class AddGroup extends Vue {
    @Prop({ default: false }) visible!: boolean;
    @Prop({ default: null }) data!: ITestGroup | null;

    private form = this.data
        ? {
              name: this.data?.name,
              description: this.data.description,
              color: this.data?.color || '',
              icon: this.data?.icon ? [{ name: this.data?.icon, uid: '1' }] : [],
          }
        : {
              name: '',
              description: '',
              color: '',
              icon: [],
          };

    private rules = {
        name: [{ required: true, message: 'Введите название', trigger: 'blur' }],
    };

    private uploadProps = {
        accept: 'image/*',
        name: 'file',
        action: `${VUE_APP_BASE_URL}/api/v1/admin/storage`,
        withCredentials: true,
        data: (file: UploadFile) => {
            const lastDot = file.name.lastIndexOf('.');

            return {
                path: 'proftest',
                size: file.size,
                type: file.type,
                name: file.name.slice(0, lastDot),
                extension: file.name.substring(lastDot + 1),
            };
        },
    };

    private change(info: { file: UploadFile; fileList: UploadFile[] }) {
        if (!info) {
            this.form.icon = [];
        } else if (info.file.status === 'done') {
            const { fileName } = info.file.response;
            this.form.icon = [{ name: fileName, uid: '1' }];
        } else if (info.file.status === 'error') {
            this.$message.error(`${info.file.name} file upload failed.`);
        } else {
            this.form.icon = [...info.fileList];
        }
    }

    private handleSubmit() {
        this.onOk({
            ...this.form,
            icon: this.form.icon.length ? this.form.icon[0].name : '',
            color: getColor(this.form.color),
        });
    }

    get imageUrl(): string | null {
        if (!this.form.icon || !this.form.icon.length) {
            return null;
        }
        return `${VUE_APP_S3_HOST}/${VUE_APP_S3_BUCKET}/proftest/${this.form.icon[0].name}`;
    }

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(
        data: Pick<ITestGroup, 'name' | 'description' | 'color' | 'icon'>,
    ): Pick<ITestGroup, 'name' | 'description' | 'color' | 'icon'> {
        return data;
    }
}
