import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import axios from 'axios';
import {
    IIsAuthenticatedResponse,
    ISignInRequest,
    ISignInResponse,
} from '@umax/proftest-types';
import { getCookie } from '@/utils'

@Module({ namespaced: true })
export class Auth extends VuexModule {
    public user: ISignInResponse | null = null;
    public role: string = '';

    @Mutation
    public setUser(user: ISignInResponse): void {
        this.user = user;
    }
    @Mutation
    public setRole(): void {
        this.role = getCookie('role');
    }

    @Action
    public updateUser(user: ISignInResponse | null): void {
        this.context.commit('setUser', user);
    }

    @Action
    public async checkAuth(): Promise<void> {
        try {
            const {
                data: { isAuthenticated },
            } = await axios.get<IIsAuthenticatedResponse>('/auth/isAuthenticated', {
                withCredentials: true,
            });
            if (isAuthenticated) {
                this.context.dispatch('getMe');
            }
        } catch (e: any) {
            this.context.commit('setUser', null);
            this.context.commit('setRole');
        }
    }

    @Action
    public async getMe(): Promise<void> {
        try {
            const response = await axios.get<ISignInResponse>('/auth/me', {
                withCredentials: true,
            });
            this.context.commit('setUser', response.data);
            this.context.commit('setRole');
        } catch (e: any) {
            this.context.commit('setUser', null);
            this.context.commit('setRole');
            console.error(e);
        }
    }

    @Action
    public async signIn(data: ISignInRequest): Promise<void> {
        try {
            const response = await axios.post<ISignInResponse>('/auth/signIn', data, {
                withCredentials: true,
            });
            this.context.commit('setUser', response.data);
            this.context.commit('setRole');
        } catch (e: any) {
            this.context.commit('setUser', null);
            this.context.commit('setRole');
            console.error(e);
        }
    }

    @Action
    public async logOut(): Promise<void> {
        try {
            await axios.get('/auth/signOut', {
                withCredentials: true,
            });
            this.context.commit('setUser', null);
            this.context.commit('setRole');
        } catch (e: any) {
            this.context.commit('setUser', null);
            this.context.commit('setRole');
            console.error(e);
        }
    }
}
