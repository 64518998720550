
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import {
    IAnswer,
    ICategory,
    ICategoryGroup,
    IQuestion,
    IQuestionCategoryGroup,
    ITest,
    QuestionType,
} from '@umax/proftest-types';
import { namespace } from 'vuex-class';
import { generateUUID } from '@/utils';

const test = namespace('Test');

interface TreeSelectItem {
    title: string;
    value: string;
    key: string;
    children?: TreeSelectItem[];
}

@Component
export default class EditQuestion extends Vue {
    @Prop({ default: false }) visible!: boolean;
    @Prop({ default: null }) data!: IQuestion;

    @test.State((state) => state.currentTest.type)
    private testType!: ITest['type'];

    @test.State((state) => state.currentTest.categories)
    private testCategories!: ICategory[];

    @test.State((state) => state.currentTest.categoryGroups)
    private testCategoryGroups!: ICategoryGroup[];

    private options = [
        { label: 'Одиночный', value: 'single' },
        { label: 'Множественный', value: 'multiple' },
        { label: 'Ручной', value: 'manual' },
        { label: 'Ввод букв по порядку', value: 'letters' },
    ];
    private editorOption = {
        modules: {
            toolbar: [
                [{ font: [] }, { size: ['small', false, 'large', 'huge'] }],

                ['bold', 'italic', 'underline', 'strike'],

                [{ color: [] }, { background: [] }],

                [{ script: 'sub' }, { script: 'super' }],

                [{ header: 1 }, { header: 2 }, 'blockquote', 'code-block'],

                [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { indent: '-1' },
                    { indent: '+1' },
                ],

                [{ direction: 'rtl' }, { align: [] }],

                ['link', 'image', 'video', 'formula'],

                ['clean'],
            ],
        },
    };

    private maxAnswerCount = 2;
    private content = '';
    private type: QuestionType = 'single';
    private categories: string[] = [];
    private categoryGroups: IQuestionCategoryGroup[] = [];
    private answers: IAnswer[] = [];

    onEditorChange({ html }: { html: string }): void {
        this.content = html;
    }

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(): IQuestion {
        return {
            text: this.content,
            type: this.type,
            categories: this.categories,
            answers: this.answers,
            categoryGroups: this.categoryGroups,
            _id: this.data._id,
            maxAnswerCount: this.maxAnswerCount,
        };
    }

    private addAnswer() {
        this.answers = [
            ...this.answers,
            {
                _id: generateUUID(),
                isRight: false,
                orderImportant: false,
                text: 'Ответ',
                zeroIfError: false,
                categoryGroups: [],
            },
        ];
    }

    private removeAnswer(id: string) {
        this.answers = this.answers.filter((a) => a._id !== id);
    }

    private changeAnswerProperty(
        id: string,
        property: keyof IAnswer,
        val: IAnswer[keyof IAnswer],
    ): void {
        const answer = this.answers.find((a) => a._id === id);
        if (answer) {
            //@ts-ignore
            answer[property] = val;
        }
    }

    private changeCategoryGroups(value: string[]): void {
        this.categoryGroups = this.testCategoryGroups.reduce(
            (acc: IQuestionCategoryGroup[], current: ICategoryGroup) => {
                const selectedCategoriesInGroup = current.categories.filter((c) =>
                    value.includes(c._id),
                );
                if (selectedCategoriesInGroup.length) {
                    return [
                        ...acc,
                        {
                            groupId: current._id,
                            categories: selectedCategoriesInGroup.map((c) => c._id),
                        },
                    ];
                }
                return acc;
            },
            [],
        );
    }

    private changeAnswerCategoryGroup(answerId: string, value: string[]) {
        const groups = this.testCategoryGroups.reduce(
            (acc: IQuestionCategoryGroup[], current: ICategoryGroup) => {
                const selectedCategoriesInGroup = current.categories.filter((c) =>
                    value.includes(c._id),
                );
                if (selectedCategoriesInGroup.length) {
                    return [
                        ...acc,
                        {
                            groupId: current._id,
                            categories: selectedCategoriesInGroup.map((c) => c._id),
                        },
                    ];
                }
                return acc;
            },
            [],
        );
        this.changeAnswerProperty(answerId, 'categoryGroups', groups);
    }

    @Watch('data')
    handleChangeData(val: IQuestion | null): void {
        if (!val) {
            return;
        }
        this.content = val.text;
        this.type = val.type;
        this.categories = val.categories;
        this.answers = val.answers;
        this.categoryGroups = val.categoryGroups;
    }

    get categoryGroupsTree(): TreeSelectItem[] {
        return this.testCategoryGroups.map((tg) => ({
            title: tg.title,
            key: tg._id,
            value: tg._id,
            children: tg.categories.map((cat) => ({
                title: cat.name,
                key: cat._id,
                value: cat._id,
            })),
        }));
    }

    get categoryGroupsValue(): string[] {
        return this.categoryGroups.reduce(
            (acc: string[], current: IQuestionCategoryGroup) =>
                acc.concat(current.categories),
            [],
        );
    }

    getAnswerCategoryGroupsValue(answer: IAnswer): string[] {
        return (answer.categoryGroups || []).reduce(
            (acc: string[], current: IQuestionCategoryGroup) =>
                acc.concat(current.categories),
            [],
        );
    }
}
