
import { Vue, Component } from 'vue-property-decorator';
import { IAnswerTemplate, ITest } from '@umax/proftest-types';
import { namespace } from 'vuex-class';

const test = namespace('Test');

@Component
export default class AnswerTemplates extends Vue {
    @test.State((state) => state.currentTest.type)
    private type!: ITest['type'];

    @test.State((state) => state.currentTest.answerTemplates)
    private data!: IAnswerTemplate[];

    @test.Mutation
    addAnswerTemplate!: () => void;

    @test.Mutation
    removeAnswerTemplate!: (id: string) => void;

    @test.Mutation
    changeAnswerTemplateText!: (data: { id: string; text: string }) => void;

    @test.Mutation
    changeAnswerTemplateWeight!: (data: { id: string; weight: number }) => void;
}
