<template>
    <div>
        <a-row :gutter="16">
            <a-col :style="{ marginBottom: '16px', display: 'flex' }">
                <a-radio-group v-model="searchTarget" @change="searchValue = ''" button-style="solid">
                    <a-radio-button value="phone">
                        Телефон
                    </a-radio-button>
                    <a-radio-button value="email">
                        Email
                    </a-radio-button>
                </a-radio-group>
                <a-col span="8">
                    <a-input-search
                        :key="searchTarget"
                        v-mask="searchTarget === 'phone' ? '+7 (###) ###-##-##' : null"
                        :max-length="searchTarget === 'phone' ? '+7 (###) ###-##-##'.length : null"
                        v-model="searchValue"
                        :placeholder="`Введите ${searchTarget === 'phone' ? 'номер телефона' : 'email'}`"
                        @search="fetchResults"
                    >
                        <a-button :disabled="!searchValue" type="primary" slot="enterButton">
                            Показать
                        </a-button>
                        <a-icon slot="prefix" :type="searchTarget === 'phone' ? 'phone' : 'mail'" />
                    </a-input-search>
                </a-col>
            </a-col>
        </a-row>
        <a-table
            :data-source="results"
            rowKey="_id"
            :bordered="true"
            :columns="columns"
            :loading="isFetching"
        >
            <a-button
                slot="id"
                slot-scope="id"
                @click="openPrinter(id)"
                v-text="`${printerUrl}${id}`"
                type="link"
            />

            <div
                slot="finished"
                slot-scope="item"
                v-text="item ? 'Пройден' : 'Не пройден'"
            />

            <div 
                slot="startDateTime"
                v-text="formatDate(date)"
                slot-scope="date" 
            />
        </a-table>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

export default {
    data() {
        return {
            results: [],
            searchValue: '',
            searchTarget: 'phone',
            columns: [
                {
                    title: 'Тип теста',
                    dataIndex: 'test.type',
                    key: 'test.type',
                    sorter: (a, b) => a.test.type.localeCompare(b.test.type),
                },
                {
                    title: 'Ссылка на результаты',
                    dataIndex: 'id',
                    key: 'id',
                    scopedSlots: { customRender: 'id' },
                },
                {
                    title: 'Статус',
                    dataIndex: 'finished',
                    key: 'finished',
                    scopedSlots: { customRender: 'finished' },
                    sorter: (a, b) => b.finished - a.finished
                },
                {
                    title: 'Дата создания',
                    dataIndex: 'startDateTime',
                    key: 'startDateTime',
                    scopedSlots: { customRender: 'startDateTime' },
                    sorter: (a, b) => moment(a.startDateTime).format('YYYYMMDD') - moment(b.startDateTime).format('YYYYMMDD'),
                },
            ],
            isFetching: false,
            printerUrl: `${process.env.VUE_APP_BASE_URL}/printer/`,
            cachedSearchValues: {}
        }
    },
    methods: {
        async fetchResults() {
            try {
                this.isFetching = true;
                const { data } = await axios
                    .get(`/helper/results-by-phone-or-email?${this.searchTarget}=${this.searchTarget === 'phone' ? this.searchValue.replace(/\D/g,'') : this.searchValue}`);
    
                this.results = data;
            } catch (e) {
                this.$message.error(this.e?.message || this.e);
            }

            this.isFetching = false;
        },
        openPrinter(id) {
            window.open(`${this.printerUrl}${id}`, '_blank');
        },
        formatDate(date) {
            return moment(date).format('DD.MM.YYYY HH:mm');
        },
    },
}
</script>

<style>
.ant-table-tbody {
    background: #fafafa;
}
</style>
