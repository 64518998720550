
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IUser } from '@umax/proftest-types';
import format from 'date-fns/format';
import { IModuleStatus } from '@/@types';
import AddUser from '@/components/modals/AddUser.vue';
import { isSuperPuperAdmin } from '@/utils';

const user = namespace('User');
const auth = namespace('Auth');

@Component({
    components: { AddUser },
})
export default class Users extends Vue {
    @user.State((state) => state.users)
    data!: IUser[];

    @user.State((state) => state.status)
    status!: IModuleStatus;

    @auth.State((state) => state.user)
    currentUser!: IUser;

    showModal = false;

    @user.Action
    fetchUsers!: () => void;

    @user.Action
    addUser!: (data: IUser) => void;

    @user.Action
    updateUser!: (params: { id: string; data: Partial<IUser> }) => void;

    @user.Action
    deleteUser!: (id: string) => void;

    private selectedUserId: string | null = null;

    get selectedUser(): IUser | null {
        if (!this.selectedUserId) {
            return null;
        }
        return this.data.find((u) => u._id === this.selectedUserId) || null;
    }

    selectUser(userId: string): void {
        this.selectedUserId = userId;
        this.showModal = true;
    }

    private createUser(data: IUser): void {
        this.addUser(data);
        this.showModal = false;
        this.selectedUserId = null;
    }

    private changeUser(data: IUser): void {
        if (!this.selectedUserId) {
            return;
        }
        this.updateUser({
            id: this.selectedUserId,
            data,
        });
        this.showModal = false;
        this.selectedUserId = null;
    }

    mounted(): void {
        if (!isSuperPuperAdmin()) {
            this.$router.push('/');
        } else {
            this.fetchUsers();
        }
    }

    private columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Активен',
            dataIndex: 'active',
            key: 'active',
            scopedSlots: { customRender: 'active' },
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '12%',
            scopedSlots: { customRender: 'createdAt' },
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '12%',
            scopedSlots: { customRender: 'updatedAt' },
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            scopedSlots: { customRender: 'action' },
        },
    ];

    formatDate(date: string): string {
        return format(new Date(date), 'dd.MM.yy HH:mm');
    }

    @Watch('user')
    userUpdated(): void {
        if (!isSuperPuperAdmin()) {
            this.$router.push('/');
        }
    }
}
