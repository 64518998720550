
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ISignInRequest } from '@umax/proftest-types';

@Component
export default class AuthModal extends Vue {
    @Prop({ default: false }) visible!: boolean;

    private form = {
        email: '',
        password: '',
    };

    private rules = {
        email: [
            { required: true, message: 'Введите email', trigger: 'blur' },
            { type: 'email', message: 'Введите валидный email', trigger: 'blur' },
        ],
        password: [{ required: true, message: 'Введите пароль', trigger: 'blur' }],
    };

    private handleSubmit() {
        this.onOk(this.form);
    }

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(data: ISignInRequest): ISignInRequest {
        return data;
    }
}
