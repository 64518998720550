
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { isEqual } from 'lodash';
import {
    ICategory,
    ICategoryGroup,
    IResult,
    IResultItem,
    IResultSection,
    ISelectedCategoryGroup,
    ITest,
} from '@umax/proftest-types';
import DraggableBody from '@/components/draggable/DraggableResultsBody.vue';
import { UploadFile } from 'ant-design-vue/types/upload';
const { VUE_APP_BASE_URL = '' } = process.env;

const test = namespace('Test');

@Component
export default class ResultsTab extends Vue {
    @test.State((state) => state.currentTest.type)
    private type!: ITest['type'];

    @test.State((state) => state.currentTest.categories)
    private categories!: ICategory[];

    @test.State((state) => state.currentTest.categoryGroups)
    private categoryGroups!: ICategoryGroup[];

    @test.State((state) => state.currentTest.results)
    private data!: IResult;

    @test.State((state) => state.currentTest.iconLegend)
    private iconLegend!: any;

    @test.Action
    updateField!: (data: { path: string; value: unknown }) => void;

    private selectedResultId: null | string = null;

    private selectedGroups: string[] = [];

    private components = {
        body: {
            wrapper: DraggableBody,
        },
    };

    private columns = [
        {
            title: 'Заголовок',
            dataIndex: 'title',
            key: 'title',
            scopedSlots: { customRender: 'res-title' },
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            scopedSlots: { customRender: 'action' },
        },
    ];

    get renderedOptions() {
        return this.iconLegend.map((option: any) =>
            Object.assign({}, option, {
                ...option,
                value: option.icon,
                title: this.$createElement('img', { attrs: { src: require(`@/assets/${option.icon}.png`) }}),
                label: [this.$createElement('img', { attrs: { src: require(`@/assets/${option.icon}.png`) }}), option.title],
            })
        );
    }


    get isProftest(): boolean {
        return this.type === 'weight' || this.type === 'prof' || this.type === 'prof2';
    }

    get selectedResult(): IResultItem | null {
        if (this.selectedResultId) {
            const result = this.data.results.find(
                (result: IResultItem) => result._id === this.selectedResultId,
            );
            return result || null;
        }
        return null;
    }

    get currentGroup(): ISelectedCategoryGroup | undefined {
        if (!this.selectedResult) {
            return undefined;
        }
        return this.selectedResult.categoryGroups.find((cg: ISelectedCategoryGroup) =>
            isEqual(cg.categories, this.selectedGroups),
        );
    }

    get resultSections(): IResultSection[] {
        if (!this.selectedResult) {
            return [];
        }
        const exists = this.selectedResult.categoryGroups.find(
            (cg: ISelectedCategoryGroup) => isEqual(cg.categories, this.selectedGroups),
        );
        const resultSections = exists ? exists.sections : [];

        resultSections.forEach(section => {
            console.log(JSON.stringify(section));

            this.fileList[`${this.currentGroup?._id}${section._id}`] = section.imageUrl ? [{ name: (section as any).imageUrl, uid: '1' }] : []
        })
        return resultSections;
    }

    changeTitle(title: string): void {
        this.updateField({
            path: 'results.title',
            value: title,
        });
    }

    private randomKey = Math.random();

    setKey(): void {
        this.randomKey = Math.random();
    }

    @test.Mutation
    addResult!: () => void;

    @test.Mutation
    changeResultTitle!: (data: { id: string; val: string }) => void;

    @test.Mutation
    changeResultCategories!: (data: { id: string; val: string[] }) => void;

    @test.Mutation
    createCategoryGroup!: (data: { selectedResultId: string; val: string[] }) => void;

    @test.Mutation('addSection')
    addSectionToGroup!: (data: { selectedResultId: string; groupId: string }) => void;

    @test.Mutation
    handleChangeSectionName!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
        name: string;
    }) => void;

    @test.Mutation
    handleChangeSectionImageUrl!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
        imageUrl: string | null;
    }) => void;

    @test.Mutation
    removeSection!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
    }) => void;

    @test.Mutation
    addItemToSection!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
    }) => void;

    @test.Mutation
    removeItemFromSection!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
        itemId: string;
    }) => void;

    @test.Mutation
    changeSectionItemText!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
        itemId: string;
        text: string;
        title: string;
    }) => void;

    @test.Mutation
    changeSectionItemSetting!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
        itemId: string;
        setting: 'bold' | 'italic';
    }) => void;

    @test.Mutation('removeResult')
    removeResultById!: (id: string) => void;

    @test.Mutation('addSubSection')
    addSubSectionToSection!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
    }) => void;

    @test.Mutation
    handleChangeSubSectionName!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
        subSectionId: string;
        name: string;
    }) => void;

    @test.Mutation
    removeSubSection!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
        subSectionId: string;
    }) => void;

    @test.Mutation
    addItemToSubSection!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
        subSectionId: string;
    }) => void;

    @test.Mutation
    removeItemFromSubSection!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
        subSectionId: string;
        itemId: string;
    }) => void;

    @test.Mutation
    changeSubSectionItemText!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
        subSectionId: string;
        itemId: string;
        text: string;
    }) => void;

    @test.Mutation
    changeSubSectionItemSetting!: (data: {
        resultId: string;
        groupId: string;
        sectionId: string;
        subSectionId: string;
        itemId: string;
        setting: 'bold' | 'italic';
    }) => void;

    removeResult(id: string): void {
        if (id === this.selectedResultId) {
            this.selectResult(null);
        }
        this.removeResultById(id);
    }

    selectResult(resultId: string | null): void {
        if (resultId) {
            if (!this.data.results.find((r) => r._id === resultId)) {
                return;
            }
        }
        this.selectedResultId = resultId;
    }

    handleChangeSelectedGroups(idx: number, value: string): void {
        this.selectedGroups = [
            ...this.selectedGroups.slice(0, idx),
            value,
            ...this.selectedGroups.slice(idx + 1),
        ];
    }

    @Watch('selectedResult')
    handleChangeSelectedResult(val: IResultItem): void {
        if (val && val.categoryGroups.length) {
            this.selectedGroups = val.categoryGroups[0].categories;
        } else {
            this.selectedGroups = [];
        }
    }

    @Watch('selectedGroups')
    checkGroupsExistsInResult(val: string[]): void {
        if (!this.selectedResult || val.length !== this.categoryGroups.length) {
            return;
        }
        const exists = this.selectedResult.categoryGroups.find(
            (cg: ISelectedCategoryGroup) => isEqual(cg.categories, val),
        );
        if (exists) {
            return;
        }

        this.createCategoryGroup({
            selectedResultId: this.selectedResultId as string,
            val,
        });
    }

    addSection(): void {
        if (!this.selectedResult) {
            return;
        }
        const currentGroup = this.selectedResult.categoryGroups.find(
            (cg: ISelectedCategoryGroup) => isEqual(cg.categories, this.selectedGroups),
        );
        if (!currentGroup) {
            return;
        }
        this.addSectionToGroup({
            selectedResultId: this.selectedResultId as string,
            groupId: currentGroup._id,
        });
    }

    addSubSection(sectionId: string): void {
        if (!this.selectedResult) {
            return;
        }
        const currentGroup = this.selectedResult.categoryGroups.find(
            (cg: ISelectedCategoryGroup) => isEqual(cg.categories, this.selectedGroups),
        );
        if (!currentGroup) {
            return;
        }
        const section = currentGroup.sections.find((s) => s._id === sectionId);
        if (!section) {
            return;
        }
        this.addSubSectionToSection({
            resultId: this.selectedResultId as string,
            groupId: currentGroup._id,
            sectionId,
        });
    }

    private customRow(data: IResultItem) {
        return {
            on: {
                click: () => {
                    this.selectResult(data._id);
                },
            },
        };
    }

    private uploadProps = (accept = 'image/*', path = 'proftest') => ({
        accept,
        name: 'file',
        action: `${VUE_APP_BASE_URL}/api/v1/admin/storage`,
        withCredentials: true,
        data: (file: UploadFile) => {
            const lastDot = file.name.lastIndexOf('.');

            return {
                path,
                size: file.size,
                type: file.type,
                name: file.name.slice(0, lastDot),
                extension: file.name.substring(lastDot + 1),
            };
        },
    });

    private fileList: any = {};

    changeGroupImage({ file, fileList }: any, resultId: string, groupId: string, sectionId: string): void {
        this.fileList[`${groupId}${sectionId}`] = fileList
        const { VUE_APP_S3_HOST = '', VUE_APP_S3_BUCKET } = process.env;

        if (file?.status === 'done') {
            const image = `${VUE_APP_S3_HOST}/${VUE_APP_S3_BUCKET}/proftest/${file.response.fileName}`
                this.handleChangeSectionImageUrl({
                    resultId,
                    groupId,
                    sectionId,
                    imageUrl: image,
                })
        }

        if (file?.status === 'removed') {
            this.handleChangeSectionImageUrl({
                resultId,
                groupId,
                sectionId,
                imageUrl: null,
            })
        }

        this.$forceUpdate();
    }
}
