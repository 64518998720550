var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('a-row',[_c('a-col',{attrs:{"span":"6"}},[_c('a-form-item',{attrs:{"label":"Заголовок"}},[_c('a-input',{attrs:{"value":_vm.data.title},on:{"change":function($event){return _vm.changeTitle($event.target.value)}}})],1)],1)],1),_c('a-button',{staticStyle:{"margin-bottom":"16px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.addResult()}}},[_vm._v(" Добавить результат ")]),(_vm.isProftest)?_c('div',[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":"6"}},[_c('a-table',{attrs:{"data-source":_vm.data.results,"rowKey":"_id","bordered":true,"customRow":_vm.customRow,"columns":_vm.columns,"components":_vm.components,"rowClassName":(item) => ({ active: item._id === _vm.selectedResultId }),"pagination":false},scopedSlots:_vm._u([{key:"res-title",fn:function(title, item){return _c('div',{},[_c('a-input',{attrs:{"slot":"title","value":title},on:{"change":function($event){return _vm.changeResultTitle({
                                    id: item._id,
                                    val: $event.target.value,
                                })}},slot:"title"})],1)}},{key:"action",fn:function(item){return _c('div',{},[_c('a-popconfirm',{attrs:{"slot":"actions","title":"Вы действительно хотите удалить результат?","ok-text":"Да","cancel-text":"Нет"},on:{"confirm":function($event){return _vm.removeResult(item._id)}},slot:"actions"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Удалить")])])],1)}}],null,false,1347713924)})],1),_c('a-col',{attrs:{"span":"16"}},[(_vm.selectedResultId)?_c('a-row',{attrs:{"gutter":16}},_vm._l((_vm.categoryGroups),function(group,idx){return _c('a-col',{key:group._id,attrs:{"span":"5"}},[_c('a-form-item',{attrs:{"label":group.title}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"value":_vm.selectedGroups[idx]},on:{"change":function($event){return _vm.handleChangeSelectedGroups(idx, $event)}}},_vm._l((group.categories),function(category){return _c('a-select-option',{key:category._id,attrs:{"value":category._id}},[_vm._v(" "+_vm._s(category.name)+" ")])}),1)],1)],1)}),1):_vm._e(),(_vm.selectedGroups.length === _vm.categoryGroups.length)?_c('div',[_c('a-button',{staticStyle:{"margin-bottom":"16px"},attrs:{"type":"primary"},on:{"click":_vm.addSection}},[_vm._v(" Добавить раздел ")]),_vm._l((_vm.resultSections),function(section){return _c('a-card',{key:section._id,staticStyle:{"margin-bottom":"16px"}},[_c('a-form-item',{attrs:{"slot":"title","label":"Заголовок1"},slot:"title"},[_c('a-input',{staticStyle:{"width":"fit-content"},attrs:{"value":section.title},on:{"change":function($event){return _vm.handleChangeSectionName({
                                        resultId: _vm.selectedResultId,
                                        groupId: _vm.currentGroup._id,
                                        sectionId: section._id,
                                        name: $event.target.value,
                                    })}}})],1),_c('a-form-item',{key:section.imageUrl,attrs:{"prop":"icon","label":"Иллюстрация"}},[(section.imageUrl)?_c('img',{staticStyle:{"max-width":"60px"},attrs:{"src":section.imageUrl}}):_vm._e(),_c('a-upload',_vm._b({attrs:{"name":"file","file-list":_vm.fileList[`${_vm.currentGroup._id}${section._id}`]},on:{"change":function($event){return _vm.changeGroupImage($event, _vm.selectedResultId, _vm.currentGroup._id, section._id)}}},'a-upload',_vm.uploadProps(),false),[(!section.imageUrl)?_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v("Загрузить")],1):_vm._e()],1)],1),_c('a',{attrs:{"slot":"extra"},on:{"click":function($event){return _vm.removeSection({
                                    resultId: _vm.selectedResultId,
                                    groupId: _vm.currentGroup._id,
                                    sectionId: section._id,
                                })}},slot:"extra"},[_c('a-icon',{attrs:{"type":"close-circle"}})],1),_c('h3',{style:({ marginBottom: '16px' })},[_vm._v(" Элементы "),_c('a-icon',{staticClass:"add-category",attrs:{"type":"plus-circle"},on:{"click":function($event){return _vm.addItemToSection({
                                        resultId: _vm.selectedResultId,
                                        groupId: _vm.currentGroup._id,
                                        sectionId: section._id,
                                    })}}})],1),_c('a-list',{attrs:{"bordered":"","data-source":section.items},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{},[_c('a',{attrs:{"slot":"extra"},on:{"click":function($event){return _vm.removeItemFromSection({
                                            resultId: _vm.selectedResultId,
                                            groupId: _vm.currentGroup._id,
                                            sectionId: section._id,
                                            itemId: item._id,
                                        })}},slot:"extra"},[_c('a-icon',{staticClass:"section-item-remove",attrs:{"type":"close-circle"}})],1),_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('a-form-item',{staticStyle:{"width":"100%","margin-right":"16px"},attrs:{"slot":"title","label":"Заголовок"},slot:"title"},[_c('a-input',{key:_vm.randomKey,staticClass:"section-item-title",style:({ marginRight: '16px' }),attrs:{"value":item.title,"placeholder":"Введите заголовок"},on:{"change":function($event){return _vm.changeSectionItemText({
                                                        resultId: _vm.selectedResultId,
                                                        groupId: _vm.currentGroup._id,
                                                        sectionId: section._id,
                                                        itemId: item._id,
                                                        text: item.text,
                                                        title: $event.target.value,
                                                    });},"blur":_vm.setKey}})],1),_c('a-form-item',{staticStyle:{"width":"100%","margin-right":"16px"},attrs:{"slot":"title","label":"Текст"},slot:"title"},[_c('div',{staticStyle:{"display":"flex","margin-top":"4px"}},[_c('a-input',{staticClass:"section-item-title",class:{
                                                        bold: item.settings.includes('bold'),
                                                        italic: item.settings.includes('italic'),
                                                    },attrs:{"placeholder":"Введите текст","value":item.text},on:{"change":function($event){return _vm.changeSectionItemText({
                                                            resultId: _vm.selectedResultId,
                                                            groupId: _vm.currentGroup._id,
                                                            sectionId: section._id,
                                                            itemId: item._id,
                                                            title: item.title,
                                                            text: $event.target.value,
                                                        })}}}),_c('span',{staticClass:"setting",class:{
                                                        active: item.settings.includes('bold'),
                                                    },on:{"click":function($event){return _vm.changeSectionItemSetting({
                                                            resultId: _vm.selectedResultId,
                                                            groupId: _vm.currentGroup._id,
                                                            sectionId: section._id,
                                                            itemId: item._id,
                                                            setting: 'bold',
                                                        })}}},[_vm._v(" B ")]),_c('span',{staticClass:"setting",class:{
                                                        active: item.settings.includes('italic'),
                                                    },on:{"click":function($event){return _vm.changeSectionItemSetting({
                                                            resultId: _vm.selectedResultId,
                                                            groupId: _vm.currentGroup._id,
                                                            sectionId: section._id,
                                                            itemId: item._id,
                                                            setting: 'italic',
                                                        })}}},[_vm._v(" I ")])],1)])],1)])])}}],null,true)}),_c('h3',{staticStyle:{"margin-top":"16px"}},[_vm._v("Подразделы")]),_c('a-button',{staticStyle:{"margin-bottom":"16px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.addSubSection(section._id)}}},[_vm._v(" Добавить подраздел ")]),(section.subsections.length)?_c('div',{staticStyle:{"border":"1px solid black","border-radius":"8px","padding":"24px"}},_vm._l((section.subsections),function(subsection){return _c('a-card',{key:subsection._id,staticStyle:{"margin-bottom":"16px"}},[_c('a-form-item',{attrs:{"slot":"title","label":"Заголовок"},slot:"title"},[_c('a-input',{staticStyle:{"width":"fit-content"},attrs:{"value":subsection.title},on:{"change":function($event){return _vm.handleChangeSubSectionName({
                                                resultId: _vm.selectedResultId,
                                                groupId: _vm.currentGroup._id,
                                                sectionId: section._id,
                                                subSectionId: subsection._id,
                                                name: $event.target.value,
                                            })}}})],1),(_vm.type === 'prof2')?_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('a-form-item',{staticStyle:{"width":"100%","margin-right":"16px"},attrs:{"slot":"title","label":"Предметы"},slot:"title"},[_c('a-select',{key:_vm.randomKey,staticStyle:{"width":"100%"},attrs:{"placeholder":"Выберите предмет","size":"large","mode":"tags"},model:{value:(subsection.tags),callback:function ($$v) {_vm.$set(subsection, "tags", $$v)},expression:"subsection.tags"}})],1),_c('a-form-item',{staticStyle:{"width":"100%","margin-right":"16px"},attrs:{"slot":"title","label":"Верхние иконки"},slot:"title"},[_c('a-select',{key:_vm.randomKey,staticStyle:{"width":"100%"},attrs:{"dropdown-match-select-width":false,"placeholder":"Выберите иконку","option-label-prop":"title","size":"large","options":_vm.renderedOptions,"mode":"multiple"},model:{value:(subsection.topIcons),callback:function ($$v) {_vm.$set(subsection, "topIcons", $$v)},expression:"subsection.topIcons"}})],1),_c('a-form-item',{staticStyle:{"width":"100%"},attrs:{"slot":"title","label":"Нижние иконки"},slot:"title"},[_c('a-select',{key:_vm.randomKey,staticStyle:{"width":"100%"},attrs:{"dropdown-match-select-width":false,"placeholder":"Выберите иконку","option-label-prop":"title","size":"large","options":_vm.renderedOptions,"mode":"multiple"},model:{value:(subsection.bottomIcons),callback:function ($$v) {_vm.$set(subsection, "bottomIcons", $$v)},expression:"subsection.bottomIcons"}})],1)],1):_vm._e(),_c('a',{attrs:{"slot":"extra"},on:{"click":function($event){return _vm.removeSubSection({
                                            resultId: _vm.selectedResultId,
                                            groupId: _vm.currentGroup._id,
                                            sectionId: section._id,
                                            subSectionId: subsection._id,
                                        })}},slot:"extra"},[_c('a-icon',{attrs:{"type":"close-circle"}})],1),_c('h3',{style:({ marginBottom: '16px' })},[_vm._v(" Элементы "),_c('a-icon',{staticClass:"add-category",attrs:{"type":"plus-circle"},on:{"click":function($event){return _vm.addItemToSubSection({
                                                resultId: _vm.selectedResultId,
                                                groupId: _vm.currentGroup._id,
                                                sectionId: section._id,
                                                subSectionId: subsection._id,
                                            })}}})],1),_c('a-list',{attrs:{"bordered":"","data-source":subsection.items},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{},[_c('a',{attrs:{"slot":"extra"},on:{"click":function($event){return _vm.removeItemFromSubSection({
                                                    resultId: _vm.selectedResultId,
                                                    groupId: _vm.currentGroup._id,
                                                    sectionId: section._id,
                                                    subSectionId: subsection._id,
                                                    itemId: item._id,
                                                })}},slot:"extra"},[_c('a-icon',{staticClass:"section-item-remove",attrs:{"type":"close-circle"}})],1),_c('div',{staticStyle:{"width":"100%"}},[_c('a-form-item',{attrs:{"label":"Текст"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('a-input',{staticClass:"section-item-title",class:{
                                                            bold: item.settings.includes('bold'),
                                                            italic: item.settings.includes(
                                                                'italic',
                                                            ),
                                                        },attrs:{"value":item.text},on:{"change":function($event){return _vm.changeSubSectionItemText({
                                                                resultId: _vm.selectedResultId,
                                                                groupId: _vm.currentGroup._id,
                                                                sectionId: section._id,
                                                                subSectionId: subsection._id,
                                                                itemId: item._id,
                                                                text: $event.target.value,
                                                            })}}}),_c('span',{staticClass:"setting",class:{
                                                            active: item.settings.includes(
                                                                'bold',
                                                            ),
                                                        },on:{"click":function($event){return _vm.changeSubSectionItemSetting({
                                                                resultId: _vm.selectedResultId,
                                                                groupId: _vm.currentGroup._id,
                                                                sectionId: section._id,
                                                                subSectionId: subsection._id,
                                                                itemId: item._id,
                                                                setting: 'bold',
                                                            })}}},[_vm._v(" B ")]),_c('span',{staticClass:"setting",class:{
                                                            active: item.settings.includes(
                                                                'italic',
                                                            ),
                                                        },on:{"click":function($event){return _vm.changeSubSectionItemSetting({
                                                                resultId: _vm.selectedResultId,
                                                                groupId: _vm.currentGroup._id,
                                                                sectionId: section._id,
                                                                subSectionId: subsection._id,
                                                                itemId: item._id,
                                                                setting: 'italic',
                                                            })}}},[_vm._v(" I ")])],1)])],1)])}}],null,true)})],1)}),1):_vm._e()],1)})],2):_vm._e()],1)],1)],1):_c('div',[_c('a-list',{attrs:{"bordered":"","data-source":_vm.data.results},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{},[_c('a-popconfirm',{attrs:{"slot":"actions","title":"Вы действительно хотите удалить результат?","ok-text":"Да","cancel-text":"Нет"},on:{"confirm":function($event){return _vm.removeResult(item._id)}},slot:"actions"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Удалить")])]),_c('a-list-item-meta',[_c('a-row',{attrs:{"slot":"title","gutter":16},slot:"title"},[_c('a-col',{attrs:{"span":"6"}},[_c('a-input',{attrs:{"value":item.title},on:{"change":function($event){return _vm.changeResultTitle({
                                        id: item._id,
                                        val: $event.target.value,
                                    })}}})],1),_c('a-col',{attrs:{"span":"10"}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"value":item.categories,"mode":"multiple"},on:{"change":function($event){return _vm.changeResultCategories({
                                        id: item._id,
                                        val: $event,
                                    })}}},_vm._l((_vm.categories),function(cat){return _c('a-select-option',{key:cat._id,attrs:{"value":cat._id}},[_vm._v(" "+_vm._s(cat.title)+" ")])}),1)],1)],1)],1)],1)}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }