
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ITest } from '@umax/proftest-types';
import { IModuleStatus } from '@/@types';

const test = namespace('Test');

@Component
export default class AddTestToGroup extends Vue {
    @test.State((state) => state.tests) tests!: ITest[];
    @test.State((state) => state.status) status!: IModuleStatus;

    @Prop({ default: false }) visible!: boolean;
    @Prop({ default: () => [] }) existsIds!: string[];

    btnText(testId: string): string {
        if (this.existsIds.includes(testId)) {
            return 'Удалить';
        }
        return 'Добавить';
    }

    btnType(testId: string): string {
        if (this.existsIds.includes(testId)) {
            return 'danger';
        }
        return 'default';
    }

    btnClick(testId: string): void {
        if (this.existsIds.includes(testId)) {
            this.removeTest(testId);
        } else {
            this.addTest(testId);
        }
    }

    mounted(): void {
        if (this.status === 'idle') {
            this.fetchTests();
        }
    }

    @test.Action
    fetchTests(): void {}

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('addTest')
    addTest(id: string): string {
        return id;
    }

    @Emit('removeTest')
    removeTest(id: string): string {
        return id;
    }
}
