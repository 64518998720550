import { render, staticRenderFns } from "./AddTestToGroup.vue?vue&type=template&id=a10f71fc&scoped=true&"
import script from "./AddTestToGroup.vue?vue&type=script&lang=ts&"
export * from "./AddTestToGroup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a10f71fc",
  null
  
)

export default component.exports