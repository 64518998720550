
import { Vue, Component } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { namespace } from 'vuex-class';
import { IResultItem } from '@umax/proftest-types';

const test = namespace('Test');

@Component({
    components: { Draggable },
})
export default class DraggableResultsBody extends Vue {
    @test.State((state) => state.currentTest.results.results)
    private data!: IResultItem[];
}
