import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import axios from 'axios';
import { IUser } from '@umax/proftest-types';
import { IModuleStatus } from '@/@types';

@Module({ namespaced: true })
export class User extends VuexModule {
    public users: IUser[] = [];
    public error: string | null = null;
    public status: IModuleStatus = 'idle';

    @Mutation
    public setStatus(status: IModuleStatus): void {
        this.status = status;
    }

    @Mutation
    public setError(error: string | null): void {
        this.error = error;
    }

    @Mutation
    public setUsers(users: IUser[]): void {
        this.users = users;
    }

    @Action
    public async fetchUsers(): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            const { data: projects } = await axios.get('/admin/users');
            this.context.commit('setUsers', projects);
            this.context.commit('setStatus', 'success');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async addUser(data: IUser): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.post('/admin/users', data);
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchUsers');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async updateUser(params: { id: string; data: Partial<IUser> }): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.patch(`/admin/users?id=${params.id}`, params.data);
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchUsers');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async deleteUser(id: string): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.delete(`/admin/users/${id}`);
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchUsers');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }
}
