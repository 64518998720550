
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ICreatProject, IProject, ITest, ITestGroup } from '@umax/proftest-types';
import { IModuleStatus } from '@/@types';
import format from 'date-fns/format';
import AddTestToGroup from '@/components/modals/AddTestToGroup.vue';
import AddProject from '@/components/modals/AddProject.vue';
import AddGroup from '@/components/modals/AddGroup.vue';

const project = namespace('Project');
const test = namespace('Test');

interface WithUnique {
    uniqueKey: string;
}

interface FormattedProject extends IProject, WithUnique {
    children: (ITestGroup & WithUnique & { children: (ITest & WithUnique)[] })[];
}

@Component({
    // eslint-disable-next-line no-undef
    components: { AddGroup, AddProject, AddTestToGroup },
})
export default class Projects extends Vue {
    @project.State((state) => state.projects) projects!: IProject[];
    @project.State((state) => state.status) status!: IModuleStatus;
    @project.State((state) => state.error) error!: string | null;
    @test.State((state) => state.tests) tests!: ITest[];

    private selectedData: null | { projectId: string; groupId: string } = null;

    private addTestModalVisible = false;
    private addProjectVisible = false;
    private addGroupVisible = false;

    private columns = [
        {
            title: 'Название проекта',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '12%',
            scopedSlots: { customRender: 'createdAt' },
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            scopedSlots: { customRender: 'action' },
        },
        {
            title: 'Проект по умолчанию',
            dataIndex: '',
            key: 'y',
            scopedSlots: { customRender: 'default' },
        },
    ];

    private customRow(data: IProject | ITestGroup) {
        return {
            on: {
                dblclick: () => {
                    if (this.isProject(data)) {
                        this.openCreatingModal(data._id);
                    } else if (this.isGroup(data)) {
                        const projectId = this.getProjectByGroupId(data._id);
                        this.openAddGroupModal(projectId, data._id);
                    }
                },
            },
        };
    }

    get selectedProject(): IProject | null {
        if (!this.selectedData?.projectId) {
            return null;
        }
        const project = this.projects.find((p) => p._id === this.selectedData?.projectId);
        return project ? ({ ...project } as IProject) : null;
    }

    get selectedGroup(): ITestGroup | null {
        if (!this.selectedData?.projectId || !this.selectedData?.groupId) {
            return null;
        }
        const project = this.projects.find((p) => p._id === this.selectedData?.projectId);
        if (!project) {
            return null;
        }
        const group = project.groups.find(
            (group) => group._id === this.selectedData?.groupId,
        );
        return group ? ({ ...group } as ITestGroup) : null;
    }

    get formattedDate(): FormattedProject[] {
        //@ts-ignore
        return this.projects.map((project) => ({
            ...project,
            uniqueKey: project._id,
            children: project.groups.map((group) => ({
                ...group,
                uniqueKey: group._id,
                children: group.tests
                    .map((id) => this.tests.find((t: ITest) => t._id === id))
                    .filter((t) => t)
                    .map((test) => ({
                        ...test,
                        uniqueKey: `${group._id}_${(test as ITest)._id}`,
                    })),
            })),
        }));
    }

    get existsTestIds(): ITest['_id'][] {
        if (this.selectedData) {
            const project = this.projects.find(
                (p) => p._id === (this.selectedData as { projectId: string }).projectId,
            );
            if (project) {
                const group = project.groups.find(
                    (g) => g._id === (this.selectedData as { groupId: string }).groupId,
                );
                if (group) {
                    return group.tests;
                }
            }
        }
        return [];
    }

    mounted(): void {
        this.fetchProjects();
    }

    @project.Action
    fetchProjects(): void {}

    @test.Action
    fetchTests(): void {}

    @project.Action
    changeDefaultProject!: (projectId: string) => void;

    @Watch('status')
    projectsLoaded(status: IModuleStatus): void {
        if (status === 'success') {
            this.fetchTests();
        } else if (status === 'error' && this.error) {
            this.$message.error(this.error);
        }
    }

    formatDate(date: string): string {
        return format(new Date(date), 'dd.MM.yy HH:mm');
    }

    isProject(data: IProject | ITestGroup): boolean {
        return 'groups' in data;
    }

    isGroup(data: IProject | ITestGroup): boolean {
        return 'tests' in data;
    }

    openTestModal(groupId: string): void {
        const projectId = this.getProjectByGroupId(groupId);
        this.selectedData = {
            projectId,
            groupId,
        };
        this.addTestModalVisible = true;
    }

    closeTestModal(): void {
        this.selectedData = null;
        this.addTestModalVisible = false;
    }

    addTest(testId: string): void {
        if (this.selectedData) {
            this.addTestToGroup({
                projectId: this.selectedData.projectId,
                groupId: this.selectedData.groupId,
                testId,
            });
        }
    }

    removeTest(testId: string): void {
        if (this.selectedData) {
            this.removeTestFromGroup({
                testId,
                projectId: this.selectedData.projectId,
                groupId: this.selectedData.groupId,
            });
        }
    }

    @project.Action
    addTestToGroup!: (data: {
        projectId: string;
        groupId: string;
        testId: string;
    }) => void;

    @project.Action
    removeTestFromGroup!: (data: {
        projectId: string;
        groupId: string;
        testId: string;
    }) => void;

    getProjectByGroupId(groupId: string): string {
        return this.projects.reduce((acc: string, current: IProject) => {
            if (acc) {
                return acc;
            }
            const group = current.groups.find((g) => g._id === groupId);
            if (group) {
                return current._id;
            }
            return '';
        }, '');
    }

    openCreatingModal(id?: string): void {
        this.addProjectVisible = true;
        if (id) {
            this.selectedData = {
                projectId: id,
                groupId: '',
            };
        }
    }

    createProject(data: ICreatProject): void {
        this.addProjectVisible = false;
        if (this.selectedData && this.selectedData.projectId) {
            this.updateProject({
                ...data,
                id: this.selectedData.projectId,
            });
        } else {
            this.addProject(data);
        }
        this.selectedData = null;
    }

    @project.Action
    addProject!: (data: ICreatProject) => void;

    @project.Action
    updateProject!: (data: ICreatProject & { id: string }) => void;

    openAddGroupModal(projectId: string, groupId?: string): void {
        this.selectedData = {
            projectId,
            groupId: groupId || '',
        };
        this.addGroupVisible = true;
    }

    createGroup(data: {
        name: string;
        description: string;
        icon: string;
        color: string;
    }): void {
        this.addGroupVisible = false;
        if (this.selectedData) {
            if (this.selectedData.groupId) {
                this.updateGroup({
                    projectId: this.selectedData.projectId,
                    ...data,
                    id: this.selectedData.groupId,
                });
            } else {
                this.addGroup({ projectId: this.selectedData.projectId, ...data });
            }
        }
        this.selectedData = null;
    }

    @project.Action
    addGroup!: (data: { name: string; description: string; projectId: string }) => void;

    @project.Action
    updateGroup!: (data: {
        name: string;
        description: string;
        projectId: string;
        id: string;
    }) => void;

    closeAddGroupModal(): void {
        this.addGroupVisible = false;
        this.selectedData = null;
    }

    removeObject(data: IProject | ITestGroup): void {
        if (this.isProject(data)) {
            this.removeProject(data._id);
        } else {
            this.removeGroup({
                projectId: this.getProjectByGroupId(data._id),
                groupId: data._id,
            });
        }
    }

    private closeAddProjectModal(): void {
        this.addProjectVisible = false;
        this.selectedData = null;
    }

    @project.Action
    removeGroup!: ({
        projectId,
        groupId,
    }: {
        projectId: string;
        groupId: string;
    }) => void;

    @project.Action
    removeProject!: (projectId: string) => void;
}
