import { v4 as uuid } from 'uuid';
import cookies from 'js-cookie';

export const generateUUID = (): string => uuid();

export const isSuperPuperAdmin = (): boolean => {
    return cookies.get('role') === 'super-puper-admin';
};

export const getCookie = (name: string): string => {
    const value = `; ${document.cookie}`;
    const parts: any = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return '';
  }