
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ICreatProject, IProject, RegistrationFormFields } from '@umax/proftest-types';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

const registrationFields: { id: RegistrationFormFields; name: string }[] = [
    { id: 'firstName', name: 'Имя' },
    { id: 'lastName', name: 'Фамилия' },
    { id: 'type', name: 'Тип' },
    { id: 'email', name: 'Почта' },
    { id: 'phone', name: 'Телефон' },
    { id: 'grade', name: 'Класс' },
    { id: 'school', name: 'Школа' },
    { id: 'city', name: 'Город' },
    { id: 'parentPhone', name: 'Телефон родителя' },
    { id: 'parentName', name: 'Имя родителя' },
    { id: 'parentEmail', name: 'Почта родителя' },
];

type FormData = Pick<
    IProject,
    'name' | 'fields' | 'alias' | 'requiredFields' | 'skipIfSingleTest' | 'crmId' | 'schoolsList' | 'schoolsListFileName'
>;

@Component
export default class AddProject extends Vue {
    @Prop({ default: false }) visible!: boolean;
    @Prop({ default: null }) data!: IProject | null;

    private form: FormData = {
        name: '',
        fields: registrationFields.map((rf) => rf.id),
        alias: '',
        requiredFields: [],
        skipIfSingleTest: true,
        crmId: '',
        schoolsList: [],
        schoolsListFileName: '',
    };

    private allFields = registrationFields;

    private rules = {
        name: [{ required: true, message: 'Введите название', trigger: 'blur' }],
        alias: [
            {
                required: true,
                message: 'Введите уникальный alias для ссылки',
                trigger: 'blur',
            },
        ],
    };

    created(): void {
        if (this.data) {
            this.form = { ...this.data };
        }
    }

    private uploadedData: string[] | null = null;
    private showSchoolsList: boolean = false;

    private async customRequest({ file }: any) {
        this.showSchoolsList = false;

        try {
            const content = await this.readFile(file);

            if (file.name.endsWith('.csv')) {
                this.form.schoolsList = this.readCSV(content);
            } else {
                this.form.schoolsList = await this.readXLSX(content);
            }

            this.$message.success(`Файл ${file.name} успешно загружен`);
            this.$set(this.form, 'schoolsListFileName', file.name);
        } catch {
            this.$message.error(`При загрузке файла ${file.name} произошла ошибка`);
        }
    }

    private readFile(file: File): Promise<string> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const result = event.target?.result as string;
                resolve(result);
            };

            if (file.name.endsWith('.csv')) {
                reader.readAsText(file, 'utf-8');
            } else {
                reader.readAsBinaryString(file);
            }
        });
    }

    private readCSV(content: string): string[] {
        const parsed: Papa.ParseResult<any> = Papa.parse(content, { header: false });
        const rows = [].concat.apply([], parsed.data);
        return rows;
    }

    private async readXLSX(content: string): Promise<string[]> {
        return new Promise((resolve) => {
            const workbook = XLSX.read(content, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const rows = [].concat.apply([], XLSX.utils.sheet_to_json(worksheet, { header: 1 }));
            
            resolve(rows);
        });
    }


    private deleteSchoolsList() {
        this.form.schoolsList = [];
        this.form.schoolsListFileName = '';
        this.showSchoolsList = false;
    }

    private handleSubmit() {
        this.onOk(this.form);
    }

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(data: ICreatProject): ICreatProject {
        return data;
    }
}
