import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import axios from 'axios';
import { IQuestion, ITest } from '@umax/proftest-types';
import { IModuleStatus } from '@/@types';
import { set } from 'lodash';
import { generateUUID } from '@/utils';

@Module({ namespaced: true })
export class Test extends VuexModule {
    public currentTest: ITest = {} as ITest;
    public tests: ITest[] = [];
    public error: string | null = null;
    public status: IModuleStatus = 'idle';
    public savingStatus: IModuleStatus = 'idle';

    @Mutation
    public setStatus(status: IModuleStatus): void {
        this.status = status;
    }

    @Mutation
    public setSavingStatus(status: IModuleStatus): void {
        this.savingStatus = status;
    }

    @Mutation
    public setError(error: string | null): void {
        this.error = error;
    }

    @Mutation
    public setTests(tests: ITest[]): void {
        this.tests = tests;
    }

    @Mutation
    public setCurrentTest(test: ITest): void {
        this.currentTest = test;
    }

    @Action
    public async fetchTests(): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            const { data: projects } = await axios.get('/admin/test/short');
            this.context.commit('setTests', projects);
            this.context.commit('setStatus', 'success');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async fetchTest(testId: string): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            const { data } = await axios.get(`/admin/test/${testId}`);
            this.context.commit('setCurrentTest', data);
            this.context.commit('setStatus', 'success');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async removeTest(testId: string): Promise<void> {
        try {
            this.context.commit('setStatus', 'loading');
            await axios.delete(`/admin/test/${testId}`);
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchTests');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action
    public async cloneTest(params: {
        id: string;
        data: Record<string, string>;
    }): Promise<void> {
        try {
            const { id, data } = params;
            this.context.commit('setStatus', 'loading');
            await axios.post(`/admin/test/clone/${id}`, data);
            this.context.commit('setStatus', 'success');
            this.context.dispatch('fetchTests');
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
        }
    }

    @Action async saveTest(): Promise<void> {
        try {
            this.context.commit('setSavingStatus', 'loading');
            await axios.patch(`/admin/test`, this.currentTest, {
                params: {
                    id: this.currentTest._id,
                },
            });
            this.context.commit('setSavingStatus', 'success');
        } catch (e: any) {
            this.context.commit('setSavingStatus', 'error');
            const message = e.message ?? e;
            if (message.includes('duplicate')) {
                this.context.commit(
                    'setError',
                    'Ошибка сохранения: URL должен быть уникальным',
                );
            } else {
                this.context.commit('setError', e.message ?? e);
            }
        }
    }

    @Action
    public updateField(data: { path: string; value: unknown }): void {
        this.context.commit(
            'setCurrentTest',
            set(this.currentTest as ITest, data.path, data.value),
        );
    }

    @Action
    public async createTest(): Promise<ITest | null> {
        try {
            this.context.commit('setStatus', 'loading');
            const { data: test } = await axios.post(`/admin/test`);
            this.context.commit('setStatus', 'success');
            return test;
        } catch (e: any) {
            this.context.commit('setStatus', 'error');
            this.context.commit('setError', e.message);
            return null;
        }
    }

    @Mutation
    public addResult(): void {
        const id = generateUUID();
        this.currentTest.results.results.push({
            _id: id,
            title: 'Результат',
            categories: [],
            categoryGroups: [],
        });
    }

    @Mutation
    public removeResult(id: string): void {
        this.currentTest.results.results = this.currentTest.results.results.filter(
            (r) => r._id !== id,
        );
    }

    @Mutation
    public changeResultTitle({ id, val }: { id: string; val: string }): void {
        const result = this.currentTest.results.results.find((r) => r._id === id);
        if (result) {
            result.title = val;
        }
    }

    @Mutation
    public changeResultCategories({ id, val }: { id: string; val: string[] }): void {
        const result = this.currentTest.results.results.find((r) => r._id === id);
        if (result) {
            result.categories = val;
        }
    }

    @Mutation
    public createCategoryGroup({
        selectedResultId,
        val,
    }: {
        selectedResultId: string;
        val: string[];
    }): void {
        const result = this.currentTest.results.results.find(
            (r) => r._id === selectedResultId,
        );
        if (result) {
            const id = generateUUID();
            result.categoryGroups.push({
                _id: id,
                categories: val,
                sections: [],
            });
        }
    }

    @Mutation
    public addSection({
        selectedResultId,
        groupId,
    }: {
        selectedResultId: string;
        groupId: string;
    }): void {
        const result = this.currentTest.results.results.find(
            (r) => r._id === selectedResultId,
        );

        if (result) {
            const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
            if (categoryGroup) {
                const id = generateUUID();
                categoryGroup.sections.push({
                    _id: id,
                    title: 'Раздел',
                    icon: '',
                    color: '',
                    items: [],
                    subsections: [],
                });
            }
        }
    }

    @Mutation
    public addSubSection({
        resultId,
        groupId,
        sectionId,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
    }): void {
        const result = this.currentTest.results.results.find((r) => r._id === resultId);

        if (result) {
            const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
            if (categoryGroup) {
                const section = categoryGroup.sections.find((s) => s._id === sectionId);
                if (section) {
                    const id = generateUUID();
                    section.subsections.push({
                        _id: id,
                        title: 'Подраздел',
                        icon: '',
                        color: '',
                        items: [],
                    });
                }
            }
        }
    }

    @Mutation
    public handleChangeSectionName({
        resultId,
        groupId,
        sectionId,
        name,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
        name: string;
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }

        const section = categoryGroup.sections.find((s) => s._id === sectionId);
        if (section) {
            section.title = name;
        }
    }

    @Mutation
    public handleChangeSectionImageUrl({
        resultId,
        groupId,
        sectionId,
        imageUrl,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
        imageUrl: string;
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }

        const section = categoryGroup.sections.find((s) => s._id === sectionId);
        if (section) {
            section.imageUrl = imageUrl;
        }
    }

    @Mutation
    public handleChangeSubSectionName({
        resultId,
        groupId,
        sectionId,
        subSectionId,
        name,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
        subSectionId: string;
        name: string;
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }

        const section = categoryGroup.sections.find((s) => s._id === sectionId);
        if (!section) {
            return;
        }

        const subSection = section.subsections.find((s) => s._id === subSectionId);
        if (subSection) {
            subSection.title = name;
        }
    }

    @Mutation
    public removeSection({
        resultId,
        groupId,
        sectionId,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }
        categoryGroup.sections = categoryGroup.sections.filter(
            (s) => s._id !== sectionId,
        );
    }

    @Mutation
    public removeSubSection({
        resultId,
        groupId,
        sectionId,
        subSectionId,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
        subSectionId: string;
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }

        const section = categoryGroup.sections.find((s) => s._id === sectionId);

        if (!section) {
            return;
        }

        section.subsections = section.subsections.filter((s) => s._id !== subSectionId);
    }

    @Mutation
    public addItemToSection({
        resultId,
        groupId,
        sectionId,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }

        const section = categoryGroup.sections.find((s) => s._id === sectionId);
        if (section) {
            section.items.push({
                _id: generateUUID(),
                text: '',
                settings: [],
            });
        }
    }

    @Mutation
    public addItemToSubSection({
        resultId,
        groupId,
        sectionId,
        subSectionId,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
        subSectionId: string;
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }

        const section = categoryGroup.sections.find((s) => s._id === sectionId);
        if (!section) {
            return;
        }

        const subSection = section.subsections.find((s) => s._id === subSectionId);

        if (subSection) {
            subSection.items.push({
                _id: generateUUID(),
                text: '',
                settings: [],
            });
        }
    }

    @Mutation
    public removeItemFromSection({
        resultId,
        groupId,
        sectionId,
        itemId,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
        itemId: string;
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }

        const section = categoryGroup.sections.find((s) => s._id === sectionId);
        if (section) {
            section.items = section.items.filter((item) => item._id !== itemId);
        }
    }

    @Mutation
    public removeItemFromSubSection({
        resultId,
        groupId,
        sectionId,
        subSectionId,
        itemId,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
        subSectionId: string;
        itemId: string;
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }

        const section = categoryGroup.sections.find((s) => s._id === sectionId);
        if (!section) {
            return;
        }

        const subSection = section.subsections.find((s) => s._id === subSectionId);

        if (subSection) {
            subSection.items = subSection.items.filter((item) => item._id !== itemId);
        }
    }

    @Mutation
    public changeSectionItemText({
        resultId,
        groupId,
        sectionId,
        itemId,
        text,
        title,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
        itemId: string;
        text: string;
        title: string;
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }

        const section = categoryGroup.sections.find((s) => s._id === sectionId);
        if (!section) {
            return;
        }

        const item: any = section.items.find((i) => i._id === itemId);
        if (item) {
            item.text = text;
            item.title = title;
        }
    }

    @Mutation
    public changeSubSectionItemText({
        resultId,
        groupId,
        sectionId,
        subSectionId,
        itemId,
        text,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
        subSectionId: string;
        itemId: string;
        text: string;
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }

        const section = categoryGroup.sections.find((s) => s._id === sectionId);
        if (!section) {
            return;
        }

        const subSection = section.subsections.find((s) => s._id === subSectionId);
        if (!subSection) {
            return;
        }

        const item = subSection.items.find((i) => i._id === itemId);
        if (item) {
            item.text = text;
        }
    }

    @Mutation
    public changeSectionItemSetting({
        resultId,
        groupId,
        sectionId,
        itemId,
        setting,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
        itemId: string;
        setting: 'bold' | 'italic';
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }

        const section = categoryGroup.sections.find((s) => s._id === sectionId);
        if (!section) {
            return;
        }
        const item = section.items.find((i) => i._id === itemId);
        if (item) {
            if (item.settings.includes(setting)) {
                item.settings = item.settings.filter((s) => s !== setting);
            } else {
                item.settings.push(setting);
            }
        }
    }

    @Mutation
    public changeSubSectionItemSetting({
        resultId,
        groupId,
        sectionId,
        subSectionId,
        itemId,
        setting,
    }: {
        resultId: string;
        groupId: string;
        sectionId: string;
        subSectionId: string;
        itemId: string;
        setting: 'bold' | 'italic';
    }): void {
        const { results } = this.currentTest.results;
        const result = results.find((r) => r._id === resultId);
        if (!result) {
            return;
        }

        const categoryGroup = result.categoryGroups.find((cg) => cg._id === groupId);
        if (!categoryGroup) {
            return;
        }

        const section = categoryGroup.sections.find((s) => s._id === sectionId);
        if (!section) {
            return;
        }

        const subSection = section.subsections.find((s) => s._id === subSectionId);
        if (!subSection) {
            return;
        }

        const item = subSection.items.find((i) => i._id === itemId);
        if (item) {
            if (item.settings.includes(setting)) {
                item.settings = item.settings.filter((s) => s !== setting);
            } else {
                item.settings.push(setting);
            }
        }
    }

    @Mutation
    addAnswerTemplate(): void {
        this.currentTest.answerTemplates.push({
            _id: generateUUID(),
            text: '',
            weight: 0,
        });
    }

    @Mutation
    removeAnswerTemplate(id: string): void {
        this.currentTest.answerTemplates = this.currentTest.answerTemplates.filter(
            (at) => at._id !== id,
        );
    }

    @Mutation
    changeAnswerTemplateText({ id, text }: { id: string; text: string }): void {
        const at = this.currentTest.answerTemplates.find(
            (template) => template._id === id,
        );
        if (at) {
            at.text = text;
        }
    }

    @Mutation
    changeAnswerTemplateWeight({ id, weight }: { id: string; weight: number }): void {
        const at = this.currentTest.answerTemplates.find(
            (template) => template._id === id,
        );
        if (at) {
            at.weight = weight;
        }
    }

    @Mutation
    addQuestion(): void {
        this.currentTest.questions.push({
            _id: generateUUID(),
            answers: [],
            categories: [],
            categoryGroups: [],
            text: `Вопросик ${this.currentTest.questions.length + 1}`,
            type: 'single',
            maxAnswerCount: 2,
        });
    }

    @Mutation
    removeQuestion(id: string): void {
        this.currentTest.questions = this.currentTest.questions.filter(
            (q) => q._id !== id,
        );
    }

    @Mutation
    updateQuestion(data: IQuestion): void {
        const { _id } = data;
        const questionIndex = this.currentTest.questions.findIndex((q) => q._id === _id);
        if (questionIndex > -1) {
            this.currentTest.questions[questionIndex] = data;
        }
    }
}
