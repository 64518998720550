
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { cloneDeep, isEqual } from 'lodash';
import { IModuleStatus } from '@/@types';
import { ITest } from '@umax/proftest-types';
import GeneralTab from '@/components/edit-test/GeneralTab.vue';
import CategoriesTab from '@/components/edit-test/CategoriesTab.vue';
import CategoryGroupsTab from '@/components/edit-test/CategoryGroupsTab.vue';
import ResultsTab from '@/components/edit-test/ResultsTab.vue';
import IconLegendTab from '@/components/edit-test/IconLegendTab.vue';
import AnswerTemplates from '@/components/edit-test/AnswerTemplatesTab.vue';
import TestPassed from '@/components/edit-test/TestPassedTab.vue';
import Questions from '@/components/edit-test/QuestionsTab.vue';
import ResultPageTab from '@/components/edit-test/ResultPageTab.vue';

const test = namespace('Test');
@Component({
    components: {
        ResultPageTab,
        Questions,
        TestPassed,
        AnswerTemplates,
        ResultsTab,
        CategoryGroupsTab,
        CategoriesTab,
        GeneralTab,
        IconLegendTab,
    },
})
export default class EditTest extends Vue {
    @test.State((state) => state.error)
    private error!: string | null;

    private savedTest!: ITest;

    @test.State((state) => state.currentTest)
    private currentTest!: ITest;

    @test.State((state) => state.status)
    private status!: IModuleStatus;

    @test.State((state) => state.savingStatus)
    private savingStatus!: IModuleStatus;

    async mounted(): Promise<void> {
        if (!this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)) {
            this.$message.error('Недопустимый идентификатор');
        } else {
            this.fetchTest(this.$route.params.id);
        }
    }

    @test.Action
    fetchTest!: (id: string) => void;

    @test.Action
    saveTest!: () => Promise<boolean>;

    @test.Action('removeTest')
    remove!: (id: string) => void;

    @Watch('status')
    testLoaded(status: IModuleStatus): void {
        if (status === 'error' && this.error) {
            this.$message.error(this.error);
        }
        if (status === 'success') {
            this.savedTest = cloneDeep(this.currentTest);
        }
    }

    async removeTest(id: string): Promise<void> {
        await this.remove(id);
        this.$router.replace('/tests');
    }

    @Watch('savingStatus')
    handleChangeSavingStatus(status: IModuleStatus): void {
        if (status === 'error' && this.error) {
            this.$message.error(this.error);
        } else if (status === 'success') {
            this.$message.success('Успешно сохранено');
            this.savedTest = cloneDeep(this.currentTest);
        }
    }
    beforeRouteLeave(to: string, from: string, next: (answer?: boolean) => void): void {
        const changed = !isEqual(this.currentTest, this.savedTest);
        if (changed) {
            const answer = window.confirm(
                'Вы хотите уйти? У вас есть несохранённые изменения!',
            );
            if (answer) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    }
}
