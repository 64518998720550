
import { Vue, Component, ProvideReactive } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ICategory, ICategoryGroup, IQuestion, ITest } from '@umax/proftest-types';
import EditQuestion from '@/components/modals/EditQuestion.vue';
import { cloneDeep } from 'lodash';
import DraggableBody from '@/components/draggable/DraggableQuestionsBody.vue';

const test = namespace('Test');
@Component({
    components: { EditQuestion },
})
export default class Questions extends Vue {
    @test.State((state) => state.currentTest.type)
    private type!: ITest['type'];

    @test.State((state) => state.currentTest.categories)
    private categories!: ICategory[];

    @test.State((state) => state.currentTest.categoryGroups)
    private categoryGroups!: ICategoryGroup[];

    @test.State((state) => state.currentTest.questions)
    private data!: IQuestion[];

    private selectedQuestionId: string | null = null;

    private columns = [
        {
            title: 'Номер',
            dataIndex: 'number',
            key: 'number',
            width: '80px',
            scopedSlots: { customRender: 'number' },
        },
        {
            title: 'Вопрос',
            dataIndex: 'text',
            key: 'text',
            scopedSlots: { customRender: 'text' },
        },
        {
            title: 'Количество ответов',
            dataIndex: 'answers',
            key: 'answers',
            scopedSlots: { customRender: 'answers' },
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            scopedSlots: { customRender: 'action' },
        },
    ];

    private components = {
        body: {
            wrapper: DraggableBody,
        },
    };

    private setSelectedQuestionId(id: string | null): void {
        this.selectedQuestionId = id;
    }

    private closeModal(): void {
        this.selectedQuestionId = null;
    }

    private saveQuestion(question: IQuestion): void {
        this.updateQuestion(question);
        this.setSelectedQuestionId(null);
        this.saveTest();
    }

    get selectedQuestion(): IQuestion | null {
        if (!this.selectedQuestionId) {
            return null;
        }
        const exists = this.data.find((q) => q._id === this.selectedQuestionId);

        if (!exists) {
            return null;
        }
        return cloneDeep(exists);
    }

    getFirstSentence(question: string): string {
        const element = document.createElement('div');
        element.innerHTML = question;
        if (element.innerText.length > 50) {
            return `${element.innerText.slice(0, 50)}...`;
        }
        return element.innerText;
    }

    private customRow(data: IQuestion) {
        return {
            on: {
                dblclick: () => {
                    this.setSelectedQuestionId(data._id);
                },
            },
        };
    }
    private createQuestion(): void {
        this.addQuestion();
        this.saveTest();
    }

    @test.Mutation
    addQuestion!: () => void;

    @test.Mutation
    removeQuestion!: (id: string) => void;

    @test.Mutation
    updateQuestion!: (data: IQuestion) => void;

    @test.Action
    saveTest!: () => void;

    @ProvideReactive('data')
    private providedQuestions = this.data;
}
