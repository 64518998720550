
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment, { Moment } from 'moment';
import { ITest } from '@umax/proftest-types';
import { UploadFile } from 'ant-design-vue/types/upload';

const test = namespace('Test');
const { VUE_APP_BASE_URL = '' } = process.env;

@Component
export default class GeneralTab extends Vue {
    @test.State((state) => state.currentTest)
    private currentTest!: ITest;

    @test.Action
    updateField!: (data: { path: string; value: unknown }) => void;

    private icon: { name: string; uid: string }[] | null = null;

    private mailTemplate: { name: string; uid: string }[] | null = null;

    private uploadProps = (accept = 'image/*', path = 'proftest') => ({
        accept,
        name: 'file',
        action: `${VUE_APP_BASE_URL}/api/v1/admin/storage`,
        withCredentials: true,
        data: (file: UploadFile) => {
            const lastDot = file.name.lastIndexOf('.');

            return {
                path,
                size: file.size,
                type: file.type,
                name: file.name.slice(0, lastDot),
                extension: file.name.substring(lastDot + 1),
            };
        },
    });

    private change(
        field: 'icon' | 'mailTemplate' = 'icon',
        info: { file: UploadFile; fileList: UploadFile[] },
    ) {
        const path = field === 'mailTemplate' ? 'mailSettings.template' : field;

        if (!info) {
            this[field] = [];
            this.updateField({
                path,
                value: null,
            });
        } else if (info.file.status === 'done') {
            const { fileName } = info.file.response;
            this[field] = [{ name: fileName, uid: '1' }];
            this.updateField({
                path,
                value: fileName,
            });
        } else if (info.file.status === 'error') {
            this.$message.error(`${info.file.name} file upload failed.`);
        } else if (info.file.status === 'removed') {
            this.updateField({
                path,
                value: null,
            });
            this[field] = [];
        } else {
            this[field] = [...info.fileList];
        }
    }

    mounted(): void {
        if (this.currentTest.icon) {
            this.icon = [{ name: this.currentTest.icon, uid: '1' }];
        }
        if (this.currentTest.mailSettings?.template) {
            this.mailTemplate = [
                { name: this.currentTest.mailSettings.template, uid: '1' },
            ];
        }
    }

    get imageUrl(): string | null {
        const { VUE_APP_S3_HOST = '', VUE_APP_S3_BUCKET } = process.env;
        if (this.icon && this.icon.length) {
            return `${VUE_APP_S3_HOST}/${VUE_APP_S3_BUCKET}/proftest/${this.icon[0].name}`;
        }
        return null;
    }

    get testLimitTime(): Moment | null {
        if (this.currentTest.timeLimit) {
            return moment(this.currentTest.timeLimit);
        }
        return null;
    }
}
