
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IUser } from '@umax/proftest-types';

@Component
export default class AddUser extends Vue {
    @Prop({ default: false }) visible!: boolean;
    @Prop({ default: null }) data!: IUser;

    private form = this.data
        ? {
              name: this.data.name,
              email: this.data.email,
              active: this.data.active,
              password: '',
          }
        : {
              name: '',
              email: '',
              active: false,
              password: '',
          };

    private rules = {
        name: [{ required: true, message: 'Введите имя', trigger: 'blur' }],
        email: [
            { required: true, message: 'Введите email', trigger: 'blur', type: 'email' },
        ],
        password: this.data
            ? []
            : [{ required: true, message: 'Минимум 4 символа', trigger: 'blur', min: 4 }],
    };

    private handleSubmit() {
        if (this.data) {
            this.onChange({
                name: this.form.name,
                active: this.form.active,
                email: this.form.email,
            });
        } else {
            this.onCreate(this.form);
        }
    }

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onCreate')
    onCreate(data: { name: string }): { name: string } {
        return data;
    }

    @Emit('onUpdate')
    onChange(data: { name: string; email: string; active: boolean }): {
        name: string;
        email: string;
        active: boolean;
    } {
        return data;
    }
}
